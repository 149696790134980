import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import {
  H1,
  H3,
  CopyBodyH2,
  Button,
  Blue,
  Purple,
  Green,
  CopySubText,
} from './index'
import Nav from '../components/nav'
import SEO from '../components/seo'
import Footer from '../components/footer'
import Box from '../components/box'
import RowLayout from '../components/rowLayout'
import CompanyIcons from '../components/companyIcons'
import Image from '../components/image'
import Hidden from '../components/hidden'

import HomePatternSvg from '../assets/svg/home/home-pattern-one.svg'
import QuoteSvg from '../assets/svg/small-quote.svg'
import CloudAcademySvg from '../assets/svg/cloud-academy-logo-big.svg'
import AvatarDefault from '../icons/avatarDefault'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const Customers = () => {
  useHotjarScript()

  return (
    <Container>
      <SEO
        title="Powered by Pulse - Pulse Customer Stories"
        description="Discover how our customers are using Pulse to promote internal alignment, reduce aimless meetings, and keep everyone on the same page, all the time."
      />
      <Nav />
      <HomePatternSvg
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          zIndex: 1,
        }}
      />
      <Banner>
        <RowLayout>
          <Box style={{ zIndex: 2, position: 'relative' }}>
            <H1 style={{ width: '52rem', maxWidth: '100%' }}>
              Powering comms from the next-gen companies
            </H1>
            <Button onClick={() => navigate('/signup')}>
              Get Pulse For Free
            </Button>
            <p id="hero-subtext">Create your workspace in {'<'}2min</p>
            <CopyBodyH2
              style={{
                marginTop: '2rem',
                marginBottom: '2rem',
                width: '100%',
              }}
            >
              Discover how our customers harnessed the flexibility of async
              communication to promote{' '}
              <Green style={{ display: 'inline' }}>internal alignment</Green>
              ,&nbsp;
              <Blue> reduce aimless meetings</Blue>, and keep{' '}
              <Purple> everyone on the same page</Purple>, all the time.
            </CopyBodyH2>
            <Hidden widthDown={600}>
              <CompanyIcons />
            </Hidden>
          </Box>
        </RowLayout>
      </Banner>
      <CaseStudyRow>
        <CompanyBackground
          style={{ backgroundImage: `url(/assets/cloud-academy-office.png)` }}
        >
          <CloudAcademySvg />
        </CompanyBackground>

        <CaseStudy>
          <H3 style={{ color: '#000' }}>
            How Cloud Academy stays aligned at scale with Pulse
          </H3>
          <CopySubText style={{ color: '#757575' }}>
            Building a culture of seamless collaboration can be tricky when you
            have multiple office locations and a rapidly growing team. Cloud
            Academy needed a platform that could connect employees across the
            country to one another
          </CopySubText>
          <Button
            style={{
              backgroundColor: '#0F0F09',
              color: '#FFFFFF',
              marginTop: '2rem',
            }}
            onClick={() => navigate('/case-study/cloud-academy')}
          >
            Read Case Study
          </Button>
          <Divider />
          <QuoteSvg />
          <CopySubText style={{ color: '#757575' }}>
            If you are remote you need something to keep everyone aligned, and
            if your people are in different time zones and from different
            cultures, you truly appreciate the difference Pulse makes.
          </CopySubText>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {/* <AvatarDefault /> */}
            <Image
              src="stefano.png"
              alt="Stefano Bellasio"
              style={{
                height: '2.25rem',
                width: '2.25rem',
                borderRadius: '100%',
              }}
              nativeImage
            />
            <Box style={{ marginLeft: '0.75rem' }}>
              <FullName>Stefano Bellasio</FullName>
              <Desc>CEO, Cloud Academy</Desc>
            </Box>
          </Box>
        </CaseStudy>
      </CaseStudyRow>

      {/* <CaseStudyRow>
        <CaseStudy>
          <H3 style={{ color: '#000' }}>
            How listed Real Estate co reached operational excellence with Pulse
          </H3>
          <CopySubText style={{ color: '#757575' }}>
            Building a culture of seamless collaboration can be tricky when you
            have multiple office locations and a rapidly growing team. Sandbox
            needed a platform that could connect employees across the country to
            one another
          </CopySubText>
          <Button
            style={{
              backgroundColor: '#0F0F09',
              color: '#FFFFFF',
              marginTop: '2rem',
            }}
            onClick={() => navigate('/case-study/cloud-academy')}
          >
            Read Case Study
          </Button>
          <Divider />
          <QuoteSvg />
          <CopySubText style={{ color: '#757575' }}>
            Producers at Sandbox need to know people to get things done.
            Structural is like one-stop shopping for people, resources, and
            ideas.
          </CopySubText>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <AvatarDefault />
            <Box style={{ marginLeft: '0.75rem' }}>
              <H3>Micheal Boswell</H3>
              <Desc>Senior Producer, Square</Desc>
            </Box>
          </Box>
        </CaseStudy>
        <CompanyBackground
          style={{ backgroundImage: `url(/assets/com-estate-company.png)` }}
        />
      </CaseStudyRow> */}
      <Footer />
    </Container>
  )
}

const Container = styled.div``

const Banner = styled.div`
  width: 100%;
  min-height: 40.75rem;
  max-height: max-content;
  background-color: black;
  background-size: cover;
  padding-bottom: 2rem;
  box-sizing: border-box;
  position: relative;
  padding-top: 4.375rem;

  #hero-subtext {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: rgba(255, 255, 255, 0.6);
    font-family: ${props => props.theme.typography.fontFamilyGTMono};

    a {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  #pulse-slack {
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-size: 1rem;
    line-height: 1.75rem;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    margin-bottom: 1.5rem;
  }
`

const Divider = styled.div`
  border: 0.0625rem solid rgba(0, 0, 0, 0.08);
  width: 100%;
  margin: 1.5rem 0;
`

const Desc = styled.span`
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #757575;
  margin: 0.125rem 0rem;
`

const CaseStudyRow = styled.div`
  padding-left: 0;
  padding-right: 0;
  height: max-content;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.core.background.inversePrimary};

  @media screen and (min-width: 37.5rem) {
    flex-direction: row;
  }

  @media screen and (min-width: 90rem) {
    padding-left: calc(50vw + 2.5rem - 37.5rem);
    padding-right: calc(50vw + 2.5rem - 37.5rem);
  }
`

const CaseStudy = styled.div`
  padding: 3rem 2rem;
  width: 100vw;
  background: #fff;
  box-sizing: border-box;
  height: max-content;
  flex: 1;
  box-sizing: border-box;
  max-width: 100%;

  @media (min-width: 37.5rem) {
    max-width: 50%;
    padding: 5.5rem 5.125rem 7rem 4.875rem;
  }
`

const CompanyBackground = styled.div`
  align-items: center;
  justify-content: center;
  height: auto;
  position: relative;
  display: none;
  flex: 1;
  max-width: 50%;
  background-size: cover;

  @media (min-width: 37.5rem) {
    display: flex;
  }
`

const FullName = styled.div`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
`

export default Customers
