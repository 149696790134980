import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={46}
      height={46}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.878}
        y={0.878}
        width={44.243}
        height={44.243}
        rx={22.122}
        fill="url(#prefix__paint0_linear)"
        stroke="#fff"
        strokeWidth={1.757}
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={5.463}
          y1={6.325}
          x2={37.95}
          y2={41.688}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#36A2ED" />
          <stop offset={1} stopColor="#CA33C6" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgComponent
